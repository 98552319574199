import { useEffect, useState } from 'react';
import { BuildsContext } from './BuildsContext';

async function fetchWeapons() {
  const response = await fetch("/data/builds.json", {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
  });
  
  return await response.json();;
}

export default function BuildsProvider(props) {
  const [weapons, setWeapons] = useState(null);

  useEffect(() => {
    fetchWeapons().then(weapons => setWeapons(weapons));
  }, [])

  return (
        <BuildsContext.Provider value={weapons}>
            {(weapons) && props.children}
        </BuildsContext.Provider>
  )
}
