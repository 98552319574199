/** @jsxImportSource @emotion/react */

import { useContext, useState } from "react"
import { Grid, Card, CardMedia, CardContent, Button, Typography, Stack, AppBar, Toolbar, useScrollTrigger, Slide, TextField, CardHeader, Dialog, Paper, List, ListItem, MenuItem, ListItemText, ListSubheader, Chip, Box, Divider, CardActionArea } from "@mui/material"
import { css } from "@emotion/react"
import { ArrowBack } from "@mui/icons-material"
import { AnimatePresence, motion } from "framer-motion"
import { Routes, Route, useNavigate, useParams } from "react-router-dom"
import GunPanel from "../misc/GunPanel";
import { BuildsContext } from "../misc/BuildsContext"

function ProsAndCons(props) {
    return (
        <Stack spacing={2} sx={{width: 430}}>
            {props.Pros && 
                <List component={Paper} subheader={<ListSubheader>Pros</ListSubheader>} sx={{border: 'solid medium #2e8f4a'}}>
                    { props.Pros.map(pro =>
                        <ListItem key={pro.Header}>
                            <ListItemText primary={pro.Header} secondary={pro.Info} />
                        </ListItem>
                    ) }
                </List>
            }
            { props.Cons &&
                <List component={Paper} subheader={<ListSubheader>Cons</ListSubheader>} sx={{border: 'solid medium #8f2e2e'}}>
                    { props.Cons.map(con =>
                        <ListItem key={con.Header}>
                            <ListItemText primary={con.Header} secondary={con.Info} />
                        </ListItem>
                    ) }
                </List> 
            }
        </Stack>
    )
}

function Content(props) {
    console.log(props)
    return (
        <Grid container style={{padding: '1rem', justifyContent: 'center'}} spacing={2}>
            <Grid item sx={{paddingBottom: 0}}>
                <Card sx={{paddingBottom: 0}}>
                  <GunPanel {...props} />
                </Card>
            </Grid>
            { (props.build.Pros || props.build.Cons) && 
                <Grid item>
                    <ProsAndCons {...props.build} />
                </Grid>
            }
        </Grid>
    )
}

function HideOnScroll(props) {
    const trigger = useScrollTrigger()

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {props.children}
        </Slide>
    )
}

function HiddenAppBar(props) {
    const navigate = useNavigate()

    return (
        <Box>
            <HideOnScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Button onClick={() => navigate("/")} startIcon={<ArrowBack/>}>Go back</Button>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
        </Box>
    )
}



export default function Inspect() {
    const { id } = useParams();
    const [ weaponName, weaponID ] = id.split("-")
    const weapons = useContext(BuildsContext);
    const weapon = weapons.find(weapon => weapon.Data.Image.toUpperCase() === weaponName.toUpperCase())
    const build = weapon.Builds.find(build => build.ID === parseInt(weaponID))

    return (
        <motion.div layout initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration:.25}} exit={{opacity: 0}} >
            <HiddenAppBar />
            <Grid container css={
                css`
                    display: flex;
                    height: 90%;
                    overflowX: clip;
                    justifyContent: center;
                `
            }>
                <Content data={weapon.Data} build={build} />
            </Grid>
        </motion.div>
    )
}