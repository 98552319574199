import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Weapons from "../Weapons/weapons"
import Inspect from "../Inspect/inspect"
import BuildsProvider from "../misc/BuildsProvider"

const router = createBrowserRouter([
    {
        path: "/",
        element: <Weapons />
    },
    {
        path: "inspect/:id",
        element: <Inspect />
    }
])

export default function Router() {
    return (
        <BuildsProvider>
            <RouterProvider router={router} />
        </BuildsProvider>
    )
}