/** @jsxImportSource @emotion/react */

import { useContext, useState } from "react";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import GunPanel from "../misc/GunPanel";
import {
    Grid,
    Card,
    CardContent,
    TextField,
    CardHeader,
    MenuItem,
    CardActionArea,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";
import { BuildsContext } from "../misc/BuildsContext";

function Gun(props) {
    return (
        <Card>
            <CardActionArea component={Link} to={`inspect/${props.image}-${props.build.ID}`} >
                <GunPanel data={props.data} build={props.build} />
            </CardActionArea>
        </Card>
    )
}

function FilteringContainer(props) {
    return (
        <Grid container spacing={1}>
            <Grid item>
                <TextField select sx={{minWidth: 120}} value={props.weaponSelection} label="Weapon" onChange={props.weaponChanged}>
                    <MenuItem value={""}>All</MenuItem>
                    { props.weapons.map(weapon => weapon.Data.Weapon).map(name => <MenuItem key={name} value={name}>{name}</MenuItem>) }
                </TextField>
            </Grid>
            <Grid item>
                <TextField select sx={{minWidth: 150}} value={props.gamemode} label="Game Mode" onChange={props.gamemodeChanged}>
                    <MenuItem value={""}>All</MenuItem>
                    <MenuItem value={"MULTIPLAYER"}>Multiplayer</MenuItem>
                    <MenuItem value={"WARZONE"}>Warzone</MenuItem>
                    <MenuItem value={"RANKED"}>Ranked</MenuItem>
                </TextField>
            </Grid>
            <Grid item>
                <TextField label="Description" value={props.descriptionSelection} onChange={props.descriptionChanged} />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel 
                    label = "Controller friendly"
                    control = {
                        <Checkbox
                            checked={props.controller}
                            onChange={props.controllerChanged}
                        />
                    }
                />
            </Grid>
        </Grid>
    )
}

function Weapon(props) {
    return (
        <>
            {props.Builds.filter(props.isBuildSelected).map(build =>
                <Grid item key={build.ID}>
                    <motion.div layout initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration:.25}} exit={{opacity: 0}} >
                        <Gun data={props.Data} image={props.Data.Image} build={build} />
                    </motion.div>
                </Grid>    
            )}
        </>
    )
}

function Content(props) {
    const [weaponSelection, setWeaponSelection] = useState("")
    const [descriptionSelection, setDescriptionSelection] = useState("")
    const [controller, setController] = useState(false)
    const [gamemode, setGamemode] = useState("")

    const weaponChanged = (event) => {
        setWeaponSelection(event.target.value)
    }

    const descriptionChanged = (event) => {
        setDescriptionSelection(event.target.value)
    }

    const buildIsSelected = (build, weapon) => {
        if(weaponSelection !== "") {
            if(weapon !== weaponSelection) return false;
        }
        if(controller) {
            if(!build.Tags.includes("CONTROLLER")) return false;
        }
        if(gamemode !== "") {
            if(!build.Tags.includes(gamemode)) return false;
        }

        return build.Description.includes(descriptionSelection)
    } 

    return (
        <Grid container style={{padding: '1rem', justifyContent: 'center'}} spacing={1}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader subheader="Apply Filters" />
                    <CardContent>
                        <FilteringContainer 
                            weapons={props.weapons} 
                            descriptionChanged={descriptionChanged} 
                            weaponChanged={weaponChanged} 
                            weaponSelection={weaponSelection} 
                            descriptionSelection={descriptionSelection} 
                            controller={controller}
                            controllerChanged={(event) => setController(event.target.checked)}
                            gamemode={gamemode}
                            gamemodeChanged={(event) => setGamemode(event.target.value)}
                        />
                    </CardContent>
                </Card>
            </Grid>

            {props.weapons.map(weapon => 
                <Weapon {...weapon} key={weapon.Data.Weapon} isBuildSelected={(build) => buildIsSelected(build, weapon.Data.Weapon)}/>        
            )}
            
        </Grid>
    )
}

export default function Weapons() {
    const weapons = useContext(BuildsContext)

    return (
        <Grid container css={
            css`
                display: flex;
                height: 90%;
                overflowX: clip;
                justifyContent: center;
            `
        }>
            <Content weapons={weapons} />
        </Grid>
    )
}