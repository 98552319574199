import './App.css';
import { ThemeProvider } from "@mui/material/styles"
import theme from './theme';
import Router from './Components/router/router';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ height:'100vh', display:"flex", justifyContent: "center"}}>
          <div style={{width:"100%", maxWidth: '1920px', height: '100%'}}>
            <Router />
          </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
