import {
  Grid,
  CardMedia,
  CardContent,
  Stack,
  CardHeader,
  Chip,
  Box,
  Tooltip
} from "@mui/material";
import { Star } from "@mui/icons-material";
import Build from "./Build";

function Title(props) {
    return (
        <Stack direction="row" spacing={1}>
            <Box>
                {props.name}
            </Box>
            { props.wip && 
                <Box>
                    <Chip sx={{borderRadius: 1, fontWeight: 'bold', color: 'white'}} size="small" color="warning" label="WIP"/> 
                </Box>
            }
            { props.best &&
                <Box>
                    <Tooltip title="Best Build">
                        <Star sx={{color: 'yellow'}} />
                    </Tooltip>
                </Box>
            }
        </Stack> 
    )
}

export default function GunPanel(props) {
    return (
        <Box>
            <CardHeader title={ <Title name={props.data.Weapon} wip={props.build.WorkInProgress} best={props.build.Best} /> } subheader={props.build.Description} />
            <CardMedia component="img" sx={{maxWidth: '400px'}} image={`/images/${props.data.Image}.png`} />
            <CardContent>
                <Build {...props} />
                <Grid container spacing={1} sx={{paddingTop: '1rem'}}>
                    {props.build.Tags.map(tag => 
                        <Grid item key={tag}>
                            <Chip label={tag} />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Box>
    )
}