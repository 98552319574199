import { useState } from "react"
import { Grid, Card, CardMedia, CardContent, InputLabel, Typography, TextField, CardHeader, Dialog, Paper, List, ListItem, MenuItem, ListItemText, ListSubheader, Chip, Box, Divider, CardActionArea } from "@mui/material"
import { css } from "@emotion/react"
import { North, South, East, West } from "@mui/icons-material"
import { AnimatePresence, motion } from "framer-motion"
import { Routes, Route, useNavigate } from "react-router-dom"

const TRANSPARENT = "#00000000"

function Tuning(props) {
    return (
        (props.value >= 0) ? 
        <Chip sx={{bgcolor: TRANSPARENT}} icon={props.icons[0]} label={"+" + props.value} /> : 
        <Chip sx={{bgcolor: TRANSPARENT}} icon={props.icons[1]} label={props.value} />
    )
}

function Attachment(props) {
    return (
        <ListItem>
            <ListItemText primary={<Typography variant="body2">{props.Name}</Typography>} secondary={props.Type} />
            {(props.Tuning != null) ?
                <Box sx={{float:"right"}}>
                    <Tuning value={props.Tuning[0]} icons={[<North key="north" />, <South key="south" />]} />
                    <Tuning value={props.Tuning[1]} icons={[<East key="east" />, <West key="west" />]} />
                </Box> 
                : null
            }
        </ListItem>
    )
}

export default function Build(props) {
    return (
        <Grid container sx={{minWidth: '360px'}}>
            <Grid item xs={12}>
                <List component={Paper} sx={{width: '100%', bgcolor: TRANSPARENT, paddingBottom: 0}} subheader={<ListSubheader>Attachments + Tuning</ListSubheader>}>
                    {props.build.Build.map((attachment, index)=> 
                        <Box key={attachment.Type}> 
                            { (index > 0) ? <Divider /> : null }
                            <Attachment {...attachment} /> 
                        </Box>
                    )}
                </List>
            </Grid>
        </Grid>
    )
}